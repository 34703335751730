import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--loyalty-provider-configurations-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'loyalty_provider_configuration[baseURL]': {
          regex_url: true
        }
      }
    })
  }
}
