import { Controller } from "@hotwired/stimulus"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream'
// Connects to data-controller="back--quarts-index"
export default class extends Controller {
  connect() {
    $('#quarts_index_date').datepicker({
      language: 'fr',
      autoclose: true,
      changeDate: () => { 
        console.log('select')
        this.filter()
      }
    }).on("show", function(e) {
      var top = parseInt($('main').css('paddingTop')) + parseInt($(".datepicker").css("top")) - 40;
      $(".datepicker").css("top", top).css('zIndex', 1060);
    }).on('changeDate', () => {
      this.filter()
    })
    $(this.element).find('select').on('select2:select select2:unselect', (e) => {
      this.filter()      
    })
  }

  removeDate(e) {
    $('#quarts_index_date').val('')
    $('#quarts_index_remove_date').addClass('d-none')
    this.filter()
  }

  filter() {
    let params = []
    if($('#quarts_index_shops').val().length) {
      params.push(`shopIds=${$('#quarts_index_shops').val().join('_')}`)
    }
    if($('#quarts_index_date').val() && $('#quarts_index_date').val().length) {
      $('#quarts_index_remove_date').removeClass('d-none')
      params.push(`date=${$('#quarts_index_date').val().split('/').join('-')}`)
    }
    let url = '/back/local_quarts'
    if(params.length) {
      url += `?${params.join('&')}`
    }
    ajaxTurboStream(url, true)  
  }
}
